

export const columns = function (type) {
    return {
        orders: [
            { key: 'favicon', label: 'User', class: 'is-user-cell', sortable: 'user.username' },
            { key: 'orderNumber', label: 'Nr', class: 'is-smaller-cell' },
            { key: 'client.shortName', class: 'is-one-fifth' },
            { key: 'trip.name', label: 'Reise', sortable: 'trip.name', class: 'is-fifteen is-hidden-on-small'},
            { key: 'trip.destinations', sortable: 'trip.destinations.name' },
            { key: 'startAt', filter: 'startAt[after]', class: 'is-smaller-cell' },
            { key: 'endAt', filter: 'startAt[before]', class: 'is-smaller-cell' },
            { key: 'duration', label: 'Dauer', sortable: 'getDuration()', class: 'is-user-cell is-hidden-on-small'},
            { key: 'hotelContingent', sortable: 'hotelContingent.getPersonsReserved()', class: 'is-hidden-on-small is-user-cell'},
            { key: 'currentStatus', filter: 'currentStatus.status', sortable: 'currentStatus.status', label:'Status' },
            { key: 'requestSourceArray', label:'Status-Update', class: 'is-120px-cell', sortable: 'currentStatus.updatedAt' },
            { key: 'traitTags', label: 'Tags', title: 'Tags',class: 'is-user-cell', sortable: false },
            { key: 'ClientInvoice', label: 'KR', title: 'Kundenrechnung',class: 'is-user-cell', sortable: false },
            { key: 'mostUrgentTodo.dueDate', label: 'Deadline', class: 'is-relative', sortable: 'mostUrgentDueDate' }
        ],

        requests: [
            { key: 'order.user', class: 'is-user-cell', sortable: 'placeholder.day.order.user.username' },
            { key: 'order.orderNumber', class: 'is-smaller-cell', label: 'Nr', sortable: 'placeholder.day.order.id' },
            { key: 'order.client.shortName', sortable: 'placeholder.day.order.client.shortName', class: 'is-larger-cell is-relative', style: 'flex-grow: 1' },
            ...type !== 'orders' ? [{
                label: 'Leistung',
                sortable: false,
                class: 'is-larger-cell is-relative',
                style: 'flex-grow: 1',
                key: {
                    hotel: 'hotel.name',
                    ferry: 'ferry.name',
                    train: 'train.name',
                    airline: 'airline.name',
                    other: 'serviceDescription'
                }[type]
            }] : [],
            { key: 'placeholder.startAt', class: 'is-smaller-cell', label: 'L-Beginn' },
            { key: 'placeholder.endAt', class: 'is-smaller-cell', label: 'L-Ende' },
            { key: 'order.currentStatus', sortable: 'placeholder.day.order.currentStatus.status', class: 'is-icon-date-cell'},
            ...['hotel', 'other', 'airline', 'train'].includes(type) ? [{ key: 'order.hotelContingent', sortable: false, class: 'is-small-cell'}] : [],
            ...type === 'ferry' ? [{ key: 'order.ferryContingent', sortable: false, class: 'is-small-cell'}] : [],
            { key: 'info.personsReserved', label: 'Kontingent', sortable: false, class: 'is-smaller-cell' },
/*
            { key: 'info.personsReserved', label: 'Reserviert', sortable: false, },
*/
            { key: 'info.standardPrice.numberFormatted', label: 'Preis DZ', class: 'is-smaller-cell' },
            { key: 'info.singlePrice.numberFormatted', label: 'Preis EZ', class: 'is-smaller-cell' },
            { key: 'requestStatus.status', label: 'Status', crudHighlightArray: ['called_off', 'declined', 'canceled'], class: 'is-smaller-cell' },
            { key: 'optionTodo.dueDate', label: 'Option', class: 'is-smaller-cell' },
        ],

        placeholders: [
            { key: 'requests', label: '', class: 'is-user-cell', sortable: false  },
            { key: 'day.order.user', class: 'is-user-cell', sortable: 'day.order.user.name' },
            { key: 'day.order.orderNumber', class: 'is-smaller-cell', label: 'Nr' },
            { key: 'day.order.client.shortName' },
            ...type !== 'orders' ? [{
                class: 'is-one-quarter is-relative',
                label: 'Leistung',
                sortable: false,
                key: {
                    hotel: 'hotel_placeholder:info.title',
                    ferry: 'ports',
                    train: 'trainStations',
                    airline: 'airports',
                    other: 'serviceDescription'
                }[type],
            }] : [],
            { key: 'startAt', class: 'is-smaller-cell', label: 'L-Beginn' },
            { key: 'endAt', class: 'is-smaller-cell', label: 'L-Ende' },
            { key: 'day.order.currentStatus' },

            ...['hotel', 'other', 'airline', 'train'].includes(type) ? [{ key: 'day.order.hotelContingent', sortable: false, class: 'is-medium-cell'}] : [],
            ...type === 'ferry' ? [{ key: 'day.order.ferryContingent', sortable: false, class: 'is-medium-cell'}] : [],
            { key: 'requests.info.personsAsked', label: 'Angefragt', sortable: false, },
            { key: 'requests.info.personsReserved', label: 'Reserviert', sortable: false },
            { key: 'requests.requestStatus', label: 'Status', sortable: false },
            { key: 'requests.optionTodo', label: 'Option', sortable: false, class: 'is-medium-cell is-relative' },
        ]
    }
}

export const getPax = function (row, key) {
    if (this.type === 'ferry') {
        return getPaxFerry(row, key)
    } else if (this.type === 'hotel') {
        return getPaxHotel(row, key)
    } else {
        return `${row.contingent[key.replace('WithExtra', '')]} Pax`
    }
}

export const getPaxHotel = function (row, key) {
    if(['new', 'requested', 'waitingList'].includes(row.requestStatus.status)) {
        key = 'askedAmountWithExtra'
    } else {
        key = 'reservedAmountWithExtra'
    }
    const pax = {
        double: !!row.contingent.doubleRooms
            ? row.contingent.doubleRooms
                .map(item => item[key])
                .reduce((a,b) => a + b, 0)
            : 0,
        single: !!row.contingent.singleRooms
            ? row.contingent.singleRooms
                .map(item => item[key])
                .reduce((a,b) => a + b, 0)
            : 0,
        other: !!row.contingent.otherRooms
            ? row.contingent.otherRooms
                .map(item => item[key])
                .reduce((a,b) => a + b, 0)
            : 0,
    }

    return `${pax.double} DZ, ${pax.single} EZ${pax.other > 0 ? `, ${pax.other} MBZ` : ''}`
}

export const getPaxFerry = function (row, key) {
    const pax = {
        double: !!row.contingent.doubleCabins
            ? row.contingent.doubleCabins
                .map(item => item[key])
                .reduce((a,b) => a + b, 0)
            : 0,
        single: !!row.contingent.singleCabins
            ? row.contingent.singleCabins
                .map(item => item[key])
                .reduce((a,b) => a + b, 0)
            : 0,
        other: !!row.contingent.otherCabins
            ? row.contingent.otherCabins
                .map(item => item[key])
                .reduce((a,b) => a + b, 0)
            : 0
    }

    const otherPax = row.contingent.otherCabins
        .reduce((sum, item) => (sum + (item[key] * item.type.persons)), 0)

    const totalPax = pax.double * 2 + pax.single + otherPax;

    if(row.type === 'night') {
        return `${pax.double} DK, ${pax.single} EK${pax.other > 0 ? `, ${pax.other} MBK` : ''}`
    } else {
        return `${totalPax} Pax`
    }
}

export const prepareFilters = function (filters) {
    const {
        _search, users, destinations, startAt, endAt,
        hotel, selectedHotel, ferry, train, airline, other,
        agency, orderStatus, requestStatus, notRequestStatus, location,
        isRequests, type, clientOfferStatus, country, organisation, tripName, traitTags,
    } = filters;

    return {
        //...startAt && (!isRequests || type === 'orders') &&{ '_order[startAt]': 'ASC' },
        ...startAt && (!isRequests || type === 'orders') && { 'startAt[after]': startAt },
        ...endAt && (!isRequests || type === 'orders') && { 'startAt[before]': endAt },


        //...startAt && type !== 'orders' && isRequests && { '_order[placeholder.startAt]': 'ASC' },
        ...startAt && type !== 'orders' && isRequests && { 'placeholder.startAt[after]': startAt },
        ...endAt && type !== 'orders' && isRequests && { 'placeholder.startAt[before]': endAt },

        ...type === 'orders' && {
            'user.id': users ? users.map(user => user.id) : [],
            'trip.destinations.id': destinations.map(({id}) => `/api/destinations/${id}`),
            'trip.destinations.country': country,
            'currentStatus.status': orderStatus,
            'organisationFilter.id': organisation,
            'traitTags': traitTags,
            _search,
            ...tripName &&  { '_search_details' : tripName},
        },
        ...(type !== 'orders' && isRequests) && { // requests
            'placeholder.day.order.user.id': users ? users.map(user => user.id) : [],

            ...!!agency && { 'agency': agency.id},
            'requestStatus.status': requestStatus,
            'placeholder.day.order.currentStatus.status': orderStatus,

            // Provider
            ...type === 'hotel' && {
                ...selectedHotel && {'hotel.id': selectedHotel.id},
                ...!!hotel.destination && { 'placeholder.area.destination.id' : hotel.destination.id },
                ...!!hotel.area && { 'placeholder.area.id' : hotel.area.id }
            },
            ...type === 'ferry' && {
                'ferry.name': _search,
                ...!!ferry.startPort && { 'startPort.id': `/api/ports/${ferry.startPort.id}` },
                ...!!ferry.endPort && { 'endPort.id': `/api/ports/${ferry.endPort.id}` },
            },
            ...type === 'train' && {
                'train.name': _search,
                ...!!train.startTrainStation && { 'startTrainStation.id': `/api/train_stations/${train.startTrainStation.id}` },
                ...!!ferry.endTrainStation && { 'endTrainStation.id': `/api/train_stations/${train.endPort.id}` },
            },
            ...type === 'airline' && {
                'airline.name': _search,
                ...!!airline.startAirport && { 'startAirport.id': `/api/airports/${airline.startAirport.id}` },
                ...!!airline.endAirport && { 'endAirport.id': `/api/airports/${airline.endAirport.id}` },
            },
            ...type === 'other' && {
                'otherService.provider.name': _search,
                ...!!other.otherServiceTypeCategory && { 'otherServiceType.category': other.otherServiceTypeCategory }
            }
        },
        ...(!type !== 'orders' && !isRequests) && { // placeholders
            'day.order.user.id': users ? users.map(user => user.id) : [],

            ...['hotel', 'other'].includes(type) && { // hide search filter for airline
                _search
            },

            'day.order.currentStatus.status': orderStatus,
            'clientOffer.status': clientOfferStatus,
            ...notRequestStatus === 'no_requests' && {
                not_requestStatus: ['requested', 'waiting_list', 'option', 'rest_request', 'rest_option', 'approved', 'paid'],
            },
            ...notRequestStatus === 'no_option' && {
                not_requestStatus: ['option', 'rest_request', 'rest_option', 'approved', 'paid']
            },
            ...notRequestStatus === 'not_approved' && {
                not_requestStatus: ['approved', 'paid']
            },

            ...type === 'hotel' && {
                ...!!hotel.destination && { 'area.destination.id' : hotel.destination.id },
            },

            ...type === 'ferry' && {
                ...!!ferry.startPort && { 'startPort.id': ferry.startPort.id },
                ...!!ferry.endPort && { 'endPort.id': ferry.endPort.id },
            },

            ...type === 'train' && {
                ...!!train.startTrainStation && { 'startTrainStation.id': train.startTrainStation.id },
                ...!!train.endTrainStation && { 'endTrainStation.id': train.endTrainStation.id },
            },
            ...type === 'airline' && {
                ...!!airline.startAirport && { 'startAirport.id': airline.startAirport.id },
                ...!!airline.endAirport && { 'endAirport.id': airline.endAirport.id },
            },
            ...type === 'other' && {
                ...!!other.otherServiceTypeCategory && { 'otherServiceType.category': other.otherServiceTypeCategory },
                ...!!location.area && { 'otherServiceType.area.id': location.area.id },
                ...!!location.destination && { 'otherServiceType.destination.id': location.destination.id },
            }
        }
    }
}
